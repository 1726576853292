export const regroupList = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  doc.querySelectorAll("ol").forEach((ol) => {
    const items = Array.from(ol.children);
    let newOl = null;

    const hasOrdered = items.some(
      (li) => li.getAttribute("data-list") === "ordered"
    );

    if (!hasOrdered) {
      return;
    }

    items.forEach((li, index) => {
      const type = li.getAttribute("data-list");

      if (type === "bullet") {
        if (!newOl) {
          newOl = document.createElement("ol");
        }
        newOl.appendChild(li);
      } else if (newOl) {
        ol.insertBefore(newOl, li);
        newOl = null;
      }

      if (newOl && index === items.length - 1) ol.appendChild(newOl);
    });
  });

  return doc.body.innerHTML;
};
